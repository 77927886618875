import React from 'react';
import Footer from '../components/Footer';
import config from '../utils/config';

function Thankyou() {
	return (
		<div className="flex flex-col items-center min-h-[100vh]">
			<div className="flex items-center flex-col py-2 px-20 bg-slate-700 self-stretch">
				<div className="flex flex-1">
					<img className="h-12 w-52" src="../assets/logo.png" />{/* <!-- TODO: --> */}
				</div>
			</div>
			<div className='flex flex-1 flex-col items-center p-6 text-center'>
				<span className='text-3xl font-bold'>Congratulazioni per il tuo acquisto!</span>
				<span className='text-xl mt-6 font-bold'>Grazie per averci scelto!</span>
				<div className='mt-6'>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-20 h-20 text-green-600">
						<path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
					</svg>
				</div>

				<span className='mt-6'><span className='text-red-600 font-bold'>Attenzione: </span>Verrai contattato telefonicamente nelle prossime ore dal nostro team in modo tale da confermare l'ordine.</span>
				<span className='mt-4'>Successivamente riceverai <span className='font-bold'>{config.product_name}</span> direttamente a casa tua senza <span className='font-bold'>nessun costo di spedizione</span> e soprattutto pagando alla consegna. <span className='font-bold'>Nessun anticipo</span>!</span>
				<span className='mt-4'>Ora che hai fatto questa scelta <span className='font-bold'>intelligente</span>, immagina tutti i progetti futuri che realizzerai con facilità e precisione. Sia che tu sia un principiante alla ricerca di <span className='font-bold'>semplicità</span> o un esperto in cerca di <span className='font-bold'>prestazioni superiori</span>, <span className='font-bold'>{config.product_name}</span> sarà il tuo alleato affidabile</span>
				<span className='mt-4'>Ti ringraziamo nuovamente per il tuo acquisto e ti auguriamo buon lavoro!</span>
			</div>
			<Footer />
		</div >
	);
}

export default Thankyou;
