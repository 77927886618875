import React, { useEffect, useState } from 'react'

export type Review = {
	avatar: string,
	name: string,
	stars: number,
	title: string,
	text: string
}

type Props = {
	review: Review
}

const Review = (props: Props) => {
	const [reviewDate, setReviewDate] = useState<Date>()

	useEffect(() => {
		const now = new Date()
		now.setDate(now.getDate() - (Math.floor(Math.random() * 20) + 1))
		setReviewDate(now)
	}, [])

	const r = props.review;
	return (
		<div className='shadow-md border-2 border-slate-100  rounded-lg p-5 self-stretch flex flex-col'>
			<div className='flex flex-row items-center mb-2'>
				<img className='h-10 w-10 mr-4 rounded-full' src={r.avatar} />
				<div className='flex flex-col'>
					<span className='font-bold'>{r.name}</span>
					<span className='text-slate-600 text-xs'>{reviewDate?.toLocaleString("it-IT").split(", ")[0]}</span>
				</div>

			</div>
			<div className='self-start'>
				{Array.from({ length: 5 }).map((s, i) => {
					if (i < r.stars) {
						return <span className='text-yellow-500'>★</span>
					} else {
						return <span className='text-gray-300'>★</span>
					}
				})}
			</div>
			<span className='text-sm'>{r.text}</span>
		</div>
	)
}

export default Review
