import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Landing from './routes/Landing';
import Thankyou from './routes/Thankyou';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing/>,
  },
	{
		path: "thankyou",
		element: <Thankyou/>
	}
]);

function App() {
	return (
		<div className="flex flex-col h-full">
			<RouterProvider router={router} />
		</div >
	);
}

export default App;
